import React, {useState, useContext, useEffect} from "react";
import UserContext from "./Context"
import { FaEnvelopeOpenText } from "react-icons/fa";



export default function Dashboard(props) {


    const user = useContext(UserContext)

    const [data, setData] = useState(
        [
            {
                title: "Emails Sent",
                dataMetric: 405,
                timeRange: "Jan 1st - Feb 1st",
            },
            {
                title: "Calls made",
                dataMetric: 20,
                timeRange: "Jan 1st - Feb 1st",             
            },
            {
                title: "Qualified Leads",
                dataMetric: 1,
                timeRange: "Jan 1st - Feb 1st",
            }])


    const [campaigns, setCampaigns] = useState([
        {
            type: "Buyers",
            area: "Montgomery County",
            state: "MD",
            creditUsed: 450,
            leadsGenerated: 1
        },
        {
            type: "Buyers",
            area: "Montgomery County",
            state: "MD",
            creditUsed: 450,
            leadsGenerated: 1
        },
        {
            type: "Buyers",
            area: "Montgomery County",
            state: "MD",
            creditUsed: 450,
            leadsGenerated: 1
        },{
            type: "Buyers",
            area: "Montgomery County",
            state: "MD",
            creditUsed: 450,
            leadsGenerated: 1
        }
    ])


    useEffect(() => {

        setCampaigns(user.campaigns)
        setData((prevData) => {
            const list = prevData.slice()
            console.log(user)
            console.log("heres prevData", prevData)
            list[0].dataMetric = user?.dashboardStats?.textsSent
            list[1].dataMetric = user?.dashboardStats?.callsMade
            list[2].dataMetric = user?.dashboardStats?.leadsGenerated
            return list
        })





    },[user])





    return (
        <>


        <div data-theme="light" className="bg-neutral w-full h-full p-10 min-h-[80vh]">


            <p className="font-1 font-bold text-3xl">Dashboard</p>

            <div className="bg-base-100  mt-5 p-4 rounded-lg">
            <div className="flex flex-row justify-between items-center">
                <p className=" font-1 text-2xl font-bold">Quick Reference</p>
                <div className="">



                {/* <select className="select font-1 rounded-none ">
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>


                </select> */}
            

                </div>
                

            </div>
            

            <div className=" border-2 rounded-none mt-3 p-2  w-full h-full">
            <div className="stats stat-vertical w-full sm:stats-horizontal stats-vertical place-items-center  items-center justify-items-center ">
                {data?.map((metric,i) => (
                    <>

                        <div className="stat"  key={i} >
                            
                            <div class="stat-title font-semibold">{metric.title} {(metric.title?.toLowerCase() === "calls made") && (
                        <>
                        <div className="badge badge-primary badge-outline font-1 font-bold ml-4">Beta</div>
                        </>
                    )}</div>
                            <div class="stat-value ">{metric.dataMetric}</div>
                            <div class="stat-desc">{metric.timeRange}</div>
                            
                        </div>

                    
                    </>

                    
                    
                    

                    

               
                    
                ))}
                
                </div>
                



                
            </div>

            </div>


            <div className="mt-4 rounded-lg bg-base-100 p-2">

              

                <div className=" p-2">

                    <p className="font-3 font-semibold text-lg">Active Campaigns</p>

                    <div className="flex flex-col gap-4 mt-2 max-h-[70vh] overflow-y-auto ">

                    {(campaigns?.length === 0) && (
                        <div className="bg-base-200 w-full min-h-[20vh] rounded-lg flex flex-row items-center justify-center">
                            <p className="font-3">No Active Campaigns</p>
                        </div>
                    )}

                    {campaigns?.map((campaign,i) => (
                        (campaign?.target?.toLowerCase()) === "homesellers" ? 
                        
                        <div className="w-full p-4 border-base-300 rounded-lg border-2 min-h-[15vh] flex flex-row items-center justify-between" key={i}>
                            <div>
                                <p className="font-1 font-bold text-xl">{campaign.areaTarget?.map((target,i) => {

                                    if (i !== campaign.areaTarget.length-1) {
                                        return target + ", "
                                    } else {
                                        return target
                                    }
                                    
                                    })}</p>
                                <p className="font-3 text-lg">Targeting <span className="text-primary font-semibold">{campaign?.target?.substring(0,1).toUpperCase() + campaign?.target?.substring(1)}</span></p>
                            <div className="mt-2 leading-snug">
                               
                                <p className="font-1">Leads generated: <span className="">{campaign?.leads.length}</span></p>
                                <p className="font-1 font-bold text-error">Credits Used: {campaign.credits}</p>
                                
                            </div>
                            </div>
                        </div> : 
                        
                        <div className="w-full p-4 border-base-300 rounded-lg border-2 min-h-[15vh] flex flex-row items-center justify-between" key={i}>
                            <div>
                                <p className="font-1 font-bold text-xl">{campaign?.address?.split(" ")[0] +" "+campaign?.address?.split(" ")[1].substring(0,1).toUpperCase() + campaign?.address?.split(" ")[1].substring(1)}</p>
                                <p className="font-3 text-lg">Targeting <span className="text-primary font-semibold">{campaign?.target?.substring(0,1).toUpperCase() + campaign?.target?.substring(1)}</span></p>
                            <div className="mt-2 leading-snug">
                               
                                <p className="font-1">Leads generated: <span className="">{campaign?.leads?.length}</span></p>
                                <p className="font-1 font-bold text-error">Credits Used: {campaign?.credits}</p>
                                
                            </div>
                            </div>
                        </div> 
                    ))}


                    </div>
                    


                </div>

            </div>



            
            
        </div>




        </>
    )
}