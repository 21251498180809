import React, {useContext} from "react";
import Footer from "./Footer";
import callApi from "./CallApi";

import UserContext from "./Context";


let plan = []
export default function Pricing(props) {
    
    const user  = useContext(UserContext)
    if (window.location.href.indexOf("localhost") > -1) {
        plan = [
            {
                link: "https://buy.stripe.com/test_eVa4kkgWUbS50x26or",
                price: "19.99",
                duration: "/month",
                priceId: ""
            },
            {
                link: "https://buy.stripe.com/test_cN2g32eOMg8l4Ni3ce",
                price: "34.99",
                duration: "/month",
                priceId: ""
            },
            {
                link: 'https://buy.stripe.com/test_8wM6ssbCA1drenS289',
                price: 19.99,
                duration: "/month",
                priceId: ""
            }, 
        ]
        

    } else {
        plan = [
            {
                link: "https://buy.stripe.com/aEU9EdeA11iegjmdQR",
                priceId: "price_1QYciCEIBr27Yq48cNp9KSiX",
                price: 19.99,
                duration: "/month"
            }, {
                link: "https://buy.stripe.com/cN28A9dvX2mi4AE28a",
                priceId: "price_1QYcjGEIBr27Yq48IGFOgFjS",
                price: "34.99",
                duration: "/month"
    
            }, {
                link: "https://buy.stripe.com/6oE5nX77ze50ffifZ1",
                priceId: "price_1QYckuEIBr27Yq48JfOkUBnV",
                price: 99.99,
                duration: "/month"
            }
            
        ]
    }
    




    // const openStripe = () => {
    //     // callApi("/create-checkout-session", "POST", {priceId: "price_1Q5DsMEIBr27Yq48zluWhgrg"}).then((res) => {
    //     //     window.location.replace(res.url)
    //     // })


    // }



    return (

        <>

        <div className="w-full bg-base-100">
            {/* <div className="bg-primary text-base-100 sm:px-10 w-full flex sm:flex-row min-h-[30vh] items-center">
      
                
            
                <div className="text-center w-full">
                    <p className="font-1 font-bold text-4xl">Cut out your hassles</p>
                    <p className="font-3 font-semibold text-2xl mt-3">Our clients generate an average of 20 qualified leads per month</p>

                </div>
    
            

            </div> */}

            <div className="bg-base-300">

           
            <div className="min-h-[20vh] flex flex-row items-center justify-items-center bg-black">
                <p className="font-1 font-semibold text-3xl text-center w-full">Here's some of our plans:</p>
            </div>


            <div className="sm:mt-4 p-2 w-full mx-auto bg-base-300 grid items-center justify-items-center sm:grid-cols-3 grid-cols-1 gap-4">

                <div className="sm:p-10 p-4 bg-neutral shadow-sm sm:min-w-96 flex flex-col items-center rounded-sm">
                    <p className="font-1 font-bold text-3xl">Starter Plan</p>
                    <p className="font-2 font-semibold italic text-lg">good to start out with</p>
                    <div className="w-full p-4 bg-base-100 mt-4">
                        <div className="font-1 ">
                            <div className="bg-base-300 px-2 py-1">
                            <p className="font-bold text-xl">What we provide</p>
                            <ul className=" flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">✅</span>2000+ potential leads contacted</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Ai-Powered lead follow-up</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Extensive Lead Information</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Time saved</li>
                            </ul>

                            </div>
                            
                            <hr className="w-full mt-2 mb-1 border-2 border-base-300 rounded-full" />

                            <div className="w-full px-2 pt-1">
                                <p className="font-1 font-semibold">What we don't provide:</p>
                                <ul className="flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">❌</span>Unqualified Leads</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Reputation Risks</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Spam/Junk messaging</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Poor Customer Satisfaction</li>
                            </ul>

                            </div>


                        </div>
                    </div>

                    <div className="w-full mt-2 text-center ">
    
                        <a className="btn w-full btn-lg btn-accent" rel="noreferrer" target="_blank" href={plan[0].link + "?prefilled_email=" + user.email}>
                            <p className="font-3 font-semibold text-xl">$19.99 a month</p>


                        </a>
             
                        <p className="mt-1 font-semibold font-1">Roughly  $9.98 per lead</p> 
                        <p className="text-xs font-3">Cancel Anytime</p>

                    </div>


                </div>
                <div className="sm:p-10 p-4 bg-neutral border-2 border-primary shadow-sm sm:min-w-96 flex flex-col items-center rounded-sm relative">
                    <div className="badge badge-accent badge-lg font-3">
                        Most Popular
                    </div>
                    <p className="font-1 font-bold text-3xl">Standard Plan</p>
                    <p className="font-2 font-semibold italic text-lg">for seasoned agents</p>
                    <div className="w-full p-4 bg-base-100 mt-4">
                        <div className="font-1 ">
                            <div className="bg-base-300 px-2 py-1">
                            <p className="font-bold text-xl">What we provide</p>
                            <ul className=" flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">✅</span>6000+ potential leads contacted</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Ai-Powered lead follow-up</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Extensive Lead Information</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Time saved</li>
                            </ul>

                            </div>
                            
                            <hr className="w-full mt-2 mb-1 border-2 border-base-300 rounded-full" />

                            <div className="w-full px-2 pt-1">
                                <p className="font-1 font-semibold">What we don't provide:</p>
                                <ul className="flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">❌</span>Unqualified Leads</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Reputation Risks</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Spam/Junk messaging</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Poor Customer Satisfaction</li>
                            </ul>

                            </div>


                        </div>
                    </div>

                    <div className="w-full mt-2 text-center ">
    
                        <a className="btn w-full btn-lg btn-accent" rel="noreferrer" target="_blank" href={plan[1].link + "?prefilled_email=" + user.email}>
                            <p className="font-3 font-bold text-2xl">$34.99 a month</p>


                        </a>
             
                        <p className="mt-1 font-semibold font-1">Roughly  $5.83 per lead</p> 
                        <p className="text-xs font-3">Cancel Anytime</p>

                    </div>


                </div>

                <div className="sm:p-10 p-4 bg-neutral shadow-sm sm:min-w-96 flex flex-col items-center rounded-sm">
                    <p className="font-1 font-bold text-3xl">Premium Plan</p>
                    <p className="font-2 font-semibold italic text-lg">for overachievers</p>
                    <div className="w-full p-4 bg-base-100 mt-4">
                        <div className="font-1 ">
                            <div className="bg-base-300 px-2 py-1">
                            <p className="font-bold text-xl">What we provide</p>
                            <ul className=" flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">✅</span>18000+ potential leads contacted</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Ai-Powered lead follow-up</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Extensive Lead Information</li>
                                <li className="font-semibold"><span className="mr-2">✅</span>Save time</li>
                            </ul>

                            </div>
                            
                            <hr className="w-full mt-2 mb-1 border-2 border-base-300 rounded-full" />

                            <div className="w-full px-2 pt-1">
                                <p className="font-1 font-semibold">What we don't provide:</p>
                                <ul className="flex flex-col gap-2 mt-2 font-3 text-lg">
                                <li className="font-semibold"><span className="mr-2">❌</span>Unqualified Leads</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Reputation Risks</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Spam/Junk messaging</li>
                                <li className="font-semibold"><span className="mr-2">❌</span>Poor Customer Satisfaction</li>
                            </ul>

                            </div>


                        </div>
                    </div>

                    <div className="w-full mt-2 text-center ">
    
                        <a className="btn w-full btn-lg btn-accent" rel="noreferrer" target="_blank" href={plan[2].link + "?prefilled_email=" + user.email}>
                            <p className="font-3 text-xl">$99.99 a month</p>


                        </a>
             
                        <p className="mt-1 font-semibold font-1">Roughly  $5.55 per lead</p> 
                        <p className="text-xs font-3">Cancel Anytime</p>

                    </div>


                </div>
                {/* <div className="font-3">

                    <p className="text-3xl font-semibold">Generate over <span className="text-error underline font-bold">20+</span> qualified leads routinely</p>

                    <div>
                        <p className="font-semibold text-lg">Don't believe in our leads? Heres how we gather them.</p>
                    </div>

                    <div className="flex flex-col mt-4 w-full gap-4">

                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Initial Outreach</p>
                            <p className="font-1 font-semibold w-5/6">Using data based on your operating location, we contact locals such as tenants in the area and local homeowners.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">2000 potential leads reached</p>

                        </div>
                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Lead Valuation</p>
                            <p className="font-1 font-semibold w-5/6">Sniphomes automatically calls those who respond to our initial outreach phase to gather basic info of what they are looking for.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">20+ leads evaluated</p>

                        </div>


                        <div className="flex-row flex justify-between  bg-base-300 p-4 items-center w-full h-full">
                        <div className="w-5/6">
                            <p className="font-3 text-lg font-bold">Conversion</p>
                            <p className="font-1 font-semibold w-5/6">When Sniphomes accesses that the client is likely to convert, our agents automatically refer them to you.</p>
                        </div>
                       
                        <p className="font-semibold p-3 w-fit bg-base-100">10-20 Clients Secured</p>

                        </div>
                        <div className="text-center flex flex-row justify-center bg-base-300 p-4 h-[20vh] items-center w-full">
                        <div>
                            <p className="font-3 font-bold text-4xl">Secure Sales</p>
                            <p className="font-semibold text-lg">Without all that hassle...</p>
                        </div>
                        
                        </div>
                        


                    </div>
                   
                    
                </div> */}
            
             





            </div>
            </div>
            
            

            <div className="py-10 mt-4 w-full h-full">
                <div className="mx-auto w-4/6">
                    <p className="font-3 text-3xl">Frequently Asked Questions (FAQ):</p>


                    <div>

                    <div className="collapse !border-none">
                        
                        <input type="radio" name="my-accordion-1" />
                        <div className="collapse-title text-xl font-medium font-1">What are credits?</div>
                            <div className="collapse-content">
                                <p className="font-3">One Credit represents one email send to a potiential lead. For example, if you use 1000 credits, that would mean that Sniphomes sent out 1000 initial outreach emails to different people.</p>
                            </div>
                        </div>


                        <div className="collapse !border-none">
                        
                        <input type="radio" name="my-accordion-1" />
                        <div className="collapse-title text-xl font-medium font-1">What is a <i>potiential lead</i>?</div>
                            <div className="collapse-content">
                                <p className="font-3">A potiential lead represents anyone that Sniphomes cold outreaches to initially.</p>
                            </div>
                        </div>

                       

                        <div className="collapse !border-none">
                        
                        <input type="radio" name="my-accordion-1" />
                        <div className="collapse-title text-xl font-medium font-1">How does Sniphomes gather its data to cold outreach?</div>
                            <div className="collapse-content">
                                <p className="font-3">Sniphomes uses a variety of public sources such as county provided public records among other internal sources to gather the most reliable data.</p>
                            </div>
                        </div>



                    </div>
                
                
                
                </div>
                
                
            </div>


            <div className="mt-4 w-full sm:flex hidden p-4 bg-base-300 flex-row justify-items-center justify-around items-center h-[40vh]">
                <div className="">
                    <p className="font-3 font-bold text-5xl">Still not convinced?</p>
                    <div className="font-1 font-bold text-2xl flex sm:flex-row flex-col gap-4 items-center mt-4">
                    <a href="/" className="btn btn-lg btn-primary text-lg font-1">
                        Request a demo
                    </a>
                    or let us
                    <a href="mailto:sniphomes@gmail.com" className="btn btn-lg btn-outline text-lg font-1">
                        contact us
                    </a>
                    
                    
                    </div>
                    <p className="font-1 text-sm">will redirect to homepage demo request page*</p>
                </div>
                
                <div></div>
                
                
            </div>



           
               
        </div>


        </>
    )

}