import React, {useContext, useState, useEffect} from "react"
import UserContext from "./Context"
import LogNav from "./LogNav"
import callApi from "./CallApi"
import Message from "./Message"
import CampaignSuccess from "./CampaignSuccess"

let timeout;
export default function CampaignDash(props) {
    



    const [newCampaign, setNewCampaign] = useState(false)
    const [asProfile, setAsProfile] = useState("true")
    const [statusMessage, setStatusMessage] = useState({message: "", type:""})
    const [campaign,setCampaign] = useState({
        target: "homesellers",
        credits: 0,
        aiThreshold: 50,
        address: "",
    })
    useEffect(() => {

        setTimeout(() => {
            if (statusMessage.message !== "") {
                timeout = setStatusMessage({message: "", type:""})
            }
        }, 5000)
        
        

    }, [statusMessage])

    

    const modifyCampaign = (e) => {

        setCampaign({...campaign, [e.target.id]: e.target.value})

    }
    
    const user = useContext(UserContext)


    const startCampaign = () => {
        clearTimeout(timeout)
        setStatusMessage({message: "", type:""})
        window.scrollTo(0, 0);

        if ((campaign.target === "homebuyers") && (campaign.address === "")) {
            setStatusMessage({message: "Enter a valid address when targeting homebuyers", type: "err"})
            return
        } else if (campaign.credits < 100) {
            setStatusMessage({message: "Each campaign needs at least an 100 credit budget", type: "err"})
            return
        } else if (campaign.credits > user.credits) {
            setStatusMessage({message: "You don't have enough credits to start this campaign", type: "err"})
            return
        }

        callApi("/startCampaign","POST", campaign).then((res) => {
            if (res.message === "insufficient credits") {
                setStatusMessage({message: "Insufficient credits to start this campaign", type: "err"})
            } else if (res.message === 'not logged in') {
                setStatusMessage({message: "Not logged in", type: "err"})
                window.location.replace("/login")
            } else if (res.code === "err") {
                setStatusMessage({message: "Something went wrong", type: "err"})
            } else if (res.code === "ok") {
                setStatusMessage({message: "All went well", type:"ok"})
                setCampaign({
                    target: "homesellers",
                    credits: 0,
                    aiThreshold: 50,
                    address: "",
                })
                setNewCampaign(true)
            } else {
                setStatusMessage({message: "Something went wrong", type: "err"})
            }

        })
    }






    return (

        <>
        {(newCampaign) ? 
        <CampaignSuccess setNewCampaign={setNewCampaign} /> : 


        <div className="sm:p-10 p-4 relative">
            {(statusMessage.message !== "") && (
                    <Message error={statusMessage.message} type={statusMessage.type} />
            )}
            <p className="font-1 font-semibold text-bold sm:text-2xl text-xl sm:pl-5 pl-2">Start a New Lead Campaign</p>
            
            <div className="w-full sm:mt-5 mt-2 bg-base-100 p-2 sm:px-5">
                <div className="bg-base-300 p-4 ">
                

                <div className="w-fit flex flex-col gap-2 mt-2">
                    <p className="font-1 font-bold">Intended target:</p>
                        <select id="target" value={campaign.target} onChange={(e) => modifyCampaign(e)} className="select select-primary font-3 font-semibold">
                        <option className="homebuyers">Homebuyers</option>
                        <option className="homesellers">Homesellers</option>

                    </select>

                    

                </div>
                {(campaign.target.toLowerCase() === "homebuyers") && (
                        <div className="mt-2 p-3 bg-base-100 w-fit">
                            <p className="font-3 font-semibold">Please enter the address of the area you are trying to target</p>
                            <p className="font-3 text-xs">Sniphomes will automatically outreach to people within a certain radius from the inputted address</p>
                            <input id="address" type="text" value={campaign.address} onChange={(e) => modifyCampaign(e)} className="input input-secondary font-1 input-sm" placeholder="Address" />
                        </div>
                    )}
                <div className="flex flex-row gap-2 mt-4 items-center">
                    <p className="font-1 font-bold">Do you plan to target your operating area (as mentioned on your profile)</p>
                    <select className="select select-sm font-3" value={asProfile} onChange={(e) => {setAsProfile(e.target.value)}}>
                        <option value={"true"}>Yes</option>
                        <option value={"false"}>No</option>
                    </select>
                </div>
                {(asProfile === "false") && (
                    <div className="text-error font-bold font-3">
                        <p>Please update your settings to your target operating area</p>
                    </div>
                )}
                {(asProfile === "true") && (
                    <p className="font-1 font-semibold text-sm">(Listed areas): {user?.operatingArea.map((area, i) => 
                {if (i !== user.operatingArea.length-1) {
                    return area + ", "
                } else {
                    return area
                }
                }
                
                
                )}</p>
                )}
                



                <div className="mt-4 bg-base-200 w-full p-2">
                    <div className="w-full h-full flex sm:flex-row flex-col items-center  gap-4 justify-items-center">
                    <p className="font-1 font-semibold">Campaign Budget:</p>
                    <div className="flex flex-row items-center bg-neutral rounded-r-full pr-2 gap-2">
                        <input id="credits" type="number" className="input  font-3" placeholder="200 Credits" value={campaign.credits} onChange={(e) => {modifyCampaign(e)}} />
                        <p className="font-1 font-bold">Credits</p>

                    </div>
                    </div>

                    <div className="p-3 bg-base-100 mt-2 font-1 font-semibold">
                        <p>This credit spending would generate roughly <span className="sm:p-2 p-1 bg-error text-bold text-lg text-neutral">{Math.floor(campaign.credits*0.001)} leads</span></p>
                        <p className="font-3 text-xs mt-2">*Note: This isn't a guarantee. The actual amount of leads generated could be higher<br/> or lower from this estimate number. This estimate doesn't account <br/> for the area you are located in.*</p>
                    
                    </div>
                    
                    
                    
                
                </div>

                <p className="font-1 font-bold text-lg mt-8">Customize your agent:</p>

                <div className="flex flex-row gap-2 items-center font-3 font-bold mt-2">
                    <p>Ai Agent Name:</p>
                    <input type="text" className="font-1 input" disabled value={user.aiSettings.name} />
                   

                </div>
                <p className="text-sm text-error font-3 font-semibold">You can change this value in your settings</p>
                
                <div className="mt-2 w-full p-3 bg-base-100">
                    <p className="font-3 font-semibold">Ai Threshold value</p>
                    
                    <input id="aiThreshold" value={campaign.aiThreshold} onChange={(e) => modifyCampaign(e)} type="range" min={0} max="100" className="range w-full range-sm sm:w-2/6 mt-2 range-warning" />
                    <p className="font-3 font-semibold">This value affects the threshold at which the ai will recommend leads to you.</p>
                    <ul className="list-disc ml-5 font font-2">
                        <li>
                        <p>The higher the value, the higher the threshold (resulting in the <strong>most </strong>qualified leads)</p>
                        </li>
                        <li>
                        <p>The lower the value, the lower the threshold (resulting in <strong>less </strong>qualified leads)</p>
                        </li>
                    </ul>
                    <p className="mt-2 font-1 font-semibold">Experiment with this value throughout your campaigns</p>
                   
                    

                </div>


                <div className="mt-4">
                    <p className="font-3 font-bold">Ai Referral Info:</p>
                    

                    <div>
                        <p className="font-1 font-semibold">When Sniphomes finds a willing lead, the lead will be provided some contact information expecting a call in the future.</p>
                        <div className="mt-2 flex flex-row items-center gap-2">
                            <p className="font-3 font-semibold">Non-Personal Phone Number</p>
                            <input type="text" className="input font-1 font-semibold" placeholder="Ex: 123-4123-1233" disabled value={user.phoneNumber} />
                        </div>
                    </div>

                </div>

                </div>

                <button className="btn btn-accent btn-lg font-3 text-xl  mt-2" onClick={startCampaign}>
                    Start Campaign

                </button>
                
                
                
                
            </div>

        </div>
        
        
        
        }

        






        </>
    )
}

