import React from "react";
import Navbar from "./Navbar"
import Footer from "./Footer"
import { Helmet } from "react-helmet"


export default function PrivacyPolicy(props) {




    return (

        <>

<Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy • Sniphomes</title>
            <meta name="keywords" content="Real Estate, Leads, Lead Generation, Finding willing buyers, finding willing sellers, sniphome, sniphomes, snip homes, snip home, signup, register, privacy policy, sniphomes policy, sniphomes privacy" />
            <meta
      name="description"
      content="Terms of Service. Sniphomes The better way to generate real estate leads. Sniphomes uses cold calling technology to automate the lead generation process."
    />
        </Helmet>
            <Navbar />




            <div className="container mx-auto my-10 p-6 bg-base-100 font-3 rounded-lg">
      <h1 className="text-4xl font-bold text-left mb-8">Privacy Policy</h1>

      <div className="prose max-w-none">
        {/* Introduction */}
        <h2 className="text-2xl font-semibold">1. Introduction</h2>
        <p>
          At Sniphomes, we value your privacy and are committed to protecting your personal information. This Privacy Policy 
          explains how we collect, use, and safeguard the data you provide to us. By using Sniphomes, you agree to the terms 
          of this Privacy Policy.
        </p>

        {/* Data Collection */}
        <h2 className="text-2xl font-semibold">2. Data Collection</h2>
        <p>
          Sniphomes collects the following personal information when you use our service:
        </p>
        <ul className="list-disc list-inside">
          <li>Full Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Operating Location</li>
          <li>State of Residence</li>
        </ul>

        {/* Use of Data */}
        <h2 className="text-2xl font-semibold">3. How We Use Your Data</h2>
        <p>
          The data we collect is used for the following purposes:
        </p>
        <ul className="list-disc list-inside">
          <li>Generating leads for real estate agents</li>
          <li>Facilitating communication with potential clients</li>
          <li>Processing payments through our payment platform, Stripe</li>
        </ul>

        {/* Data Sharing */}
        <h2 className="text-2xl font-semibold">4. Data Sharing</h2>
        <p>
          Sniphomes does not sell, share, or trade your personal information with third parties, except for the following:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <strong>Stripe</strong>: We share data such as your name, email, and payment information with Stripe to process 
            payments. Stripe's privacy policy governs how your data is handled through their platform.
          </li>
        </ul>

        {/* Cookies and Tracking */}
        <h2 className="text-2xl font-semibold">5. Cookies and Tracking</h2>
        <p>
          Sniphomes does not use cookies or other tracking technologies to collect user behavior data. We respect your privacy 
          and ensure a transparent experience while using our service.
        </p>

        {/* Security */}
        <h2 className="text-2xl font-semibold">6. Data Security</h2>
        <p>
          Your personal data is encrypted before storage, and we use the latest security features to ensure its safety. We 
          implement industry-standard measures, including encryption, to protect against unauthorized access or disclosure 
          of your information.
        </p>

        {/* User Rights */}
        <h2 className="text-2xl font-semibold">7. User Rights</h2>
        <p>
          You have the right to request access to, correction, or deletion of your personal information. If you wish to 
          exercise these rights, please contact us at <a href="mailto:sniphomes@gmail.com" className="text-primary underline">sniphomes@gmail.com</a>.
        </p>

        {/* Data Retention */}
        <h2 className="text-2xl font-semibold">8. Data Retention</h2>
        <p>
          We retain your personal data for as long as necessary to fulfill the purposes for which it was collected, including 
          legal or reporting requirements. Upon termination of your account or service, we will securely delete your personal 
          information.
        </p>

        {/* Age Restrictions */}
        <h2 className="text-2xl font-semibold">9. Age Restrictions</h2>
        <p>
          Sniphomes is intended for use by licensed real estate agents who are at least 18 years of age. We do not knowingly 
          collect personal information from anyone under 18.
        </p>

        {/* Contact Information */}
        <h2 className="text-2xl font-semibold">10. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us at:
        </p>
        <p>
          Email: <a href="mailto:sniphomes@gmail.com" className="text-primary underline">sniphomes@gmail.com</a>
        </p>
      </div>

    
    </div>

      <Footer />


        </>




    )

}