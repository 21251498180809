import React, {useState, useContext, useEffect} from "react";

import { Helmet } from "react-helmet"
import Navbar from "./Navbar";
import Loading from "./Loading"
import Settings from "./Settings"
import LogTop from "./LogTop";
import Leads from "./Leads";
import LogNav from "./LogNav";
import Greet from "./Greet";
import UserContext from "./Context"
import Footer from "./Footer"
import Dashboard from "./Dashboard";
import Popup from "./Popup";
import Pricing from "./Pricing"
import CampaignDash from "./CampaignDash";
import callApi from "./CallApi"
import AccountWarning from "./AccountWarning";


function Main(props) {


    const [loading, setLoading] = useState(true)
    const [navOpen, setNavOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    
    




    const [mainNav, setMainNav] = useState("Dashboard")


    const [user, setUser] = useState({
        name: "Sebastian",
        email: "sample@example.com",
        credits: 2000,
        state: "Maryland",
        areas: ["Montgomery County", "Frederick", "Baltimore", "DC"],
        phoneNum: "301-424-2123",
        aiName: "Steven",
   
        plan: {
            active: true,
            plan: "Basic Plan",
            renewal: "9/14/2022"
        }
    })
    // useEffect(() => {
    //     setNavOpen(false)
    // },[mainNav])


    useEffect(() => {
        window.scrollTo(0,0)

    }, [mainNav])

    useEffect(() => {
        callApi("/getUser", "GET", "").then((res) => {
            if (res.code === "ok") {
                if (window.innerWidth < 460 ) {
                    console.log(window.innerWidth)
                    setIsMobile(true)
                } else {
                    console.log(window.innerWidth)
                    setIsMobile(false)
                }
                setUser(res.message)
                setLoading(false)
            } else {
                window.location.replace("/login")
            }

        })


        




        


    }, [])




    return (


        <>  

        <Helmet>
            <meta charSet="utf-8" />
            <title>Dashboard • Sniphomes</title>
            <meta name="keywords" content="Real Estate, Leads, Lead Generation, Finding willing buyers, finding willing sellers, sniphome, sniphomes, snip homes, dashboard, dash board" />
            <meta
      name="description"
      content="Go to your Dashboard. The better way to generate real estate leads. Sniphomes uses cold calling technology to automate the lead generation process."
    />
        </Helmet>




        <section className="relative">
        {(loading) && (
            <Loading />
        )}
        <UserContext.Provider value={user}>
        
        
        
        {(!isMobile) && (
            <>
            <LogTop setMainNav={setMainNav} />
            <LogNav setMainNav={setMainNav} />
            
            <div className="flex flex-row">
            
            

           <div className="w-full h-full relative ml-[15vw]">
               {(mainNav !== "Settings") && (
                   <AccountWarning />
               )}
               <Greet />
               {(mainNav === "Dashboard") && (
                   <Dashboard />
               )}
               {(mainNav === "Campaign") && (
                   <CampaignDash />
               )}
               {(mainNav === "Pricing") && (
                   <Pricing />
               )}
               {(mainNav === "Leads") && (
                   <Leads />
               )}
               {(mainNav === "Settings") && (
                   <Settings setMainNav={setMainNav} setUser={setUser} />
               )}
               {/* <Footer /> */}

               


               {(mainNav !== "Campaign") && (
               <div className="sm:block hidden">
                   <div className="fixed bottom-0 right-[10vw] z-20 bg-accent px-4 pt-1 font-1 rounded-t-lg">
           
               <div className="flex flex-row gap-2 select-none cursor-pointer" onClick={(e) => setMainNav("Campaign")}>
                   <p>Start a new campaign</p>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                       <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clipRule="evenodd" />
                   </svg>
               </div>
          
           



         




           
       </div>

               </div>
           )}
               
           <Footer />

           </div>
       </div>
       </>
        )}

        {(isMobile) && (
            <>

            <LogTop setMainNav={setMainNav} />

            <div className="min-w-screen">

                {/* Would trigger navbar */}
                <div className="fixed bottom-2 right-2 z-50" onClick={() => setMainNav("Navbar")}>
                    <button className="btn !btn-circle ">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
</svg>

                    </button>

                </div>

                {(mainNav !== "Settings") && (
                   <AccountWarning />
               )}
               {(mainNav !== "Navbar") && (
                <Greet />
               )}
               
               {(mainNav === "Dashboard") && (
                   <Dashboard />
               )}
               {(mainNav === "Campaign") && (
                   <CampaignDash />
               )}
               {(mainNav === "Navbar") && (
                     <LogNav setMainNav={setMainNav} />
               )}
               {(mainNav === "Pricing") && (
                   <Pricing />
               )}
               {(mainNav === "Leads") && (
                   <Leads setUser={setUser} />
               )}
               {(mainNav === "Settings") && (
                   <Settings setMainNav={setMainNav} setUser={setUser} />
               )}
               

            </div>








            </>
        )}
        
        
        
        
        </UserContext.Provider>
        </section>


        


        
        

        </>
    )
}

export default Main