import React, {useState, useContext, useEffect} from "react";
import UserContext from "./Context"
import callApi from "./CallApi"


export default function Leads(props) {

    const [currentTranscript, setCurrentTranscript] = useState(null)
    const user = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    

    


    const [leads, setLeads] = useState(
        [
            // {   
            //     date: "9/14/2024",
            //     new: true,
            //     phoneNumber: "391-123-1232",
            //     action: "Buying",
            //     area: "Montgomery County",
            //     transcript: [
            //         {
            //             sender: "Other",
            //             message: "Hi what's going on"
            //         },
            //         {
            //             sender: "You",
            //             message: "Hi, I'm calling about your property in Montgomery County"
            //         },
            //         {
            //             sender: "Other",
            //             message: "Oh, I'd love to buy your property"
            //     }],
            //     summary: "Is extremely interested in buying a house with a 500k budget, 2 bedrooms and 2 bathrooms for a family of 4."

            // },
            // {
            //     date: "9/14/2024",
            //     new: true,
            //     phoneNumber: "391-123-1232",
            //     action: "Buying",
            //     area: "Montgomery County",
            //     transcript: [
            //         {
            //             sender: "Other",
            //             message: "Hi what's going on"
            //         },
            //         {
            //             sender: "You",
            //             message: "Hi, I'm calling about your property in Montgomery County"
            //         },
            //         {
            //             sender: "Other",
            //             message: "Oh, I'd love to buy your property"
            //     }],
            //     summary: "Is extremely interested in buying a house with a 500k budget, 2 bedrooms and 2 bathrooms for a family of 4."

            // },
        ]
    )

    const [oldLeads, setOldLeads] = useState([])

    useEffect(() => {
        
        (async () => {
            setLoading(true)
            callApi("/getLeads", "GET").then((res) => {
                if (res.code === "err") {
                    window.location.replace("/login")
                } else {
                    const newLeads = []
                    const initialOldLeads = []
                    
                    res.message.map(async(lead,i) => {
                        if (lead.date) {
    
                            lead.date = new Date(lead.date).toLocaleDateString()
    
                            if (lead.new) {
                                newLeads.push(lead)
                            } else {
                                initialOldLeads.push(lead)
                            }
    
                        }
                        
    
                    })
                    setLeads(newLeads);
                    setOldLeads(initialOldLeads)
                    setLoading(false)
                }
            })
        })();
        
        
    }, [])

    const changeLeadStatus = (i, lead) => {

        callApi("/updateLeadStatus", "POST", {
            uuid: lead.uuid
        }).then((res) => {
            if (res.code === "err") {
                window.location.replace("/login")
            } else {
                setOldLeads((prevLeads) => [...prevLeads, lead])
                setLeads((prevLeads) => {
                    return prevLeads.filter((__,k) => i!==k)
                })
            
            }

        })

       

    }

    


    




    return (

        <>
        {/* Only triggers when a window is open */}
        {(currentTranscript !== null) && (
            <>

            <div className="fixed w-screen z-30 top-0 left-0 h-screen opacity-75 bg-black">
            
            </div>


            <div className="fixed w-screen top-2 left-0 z-50 h-fit">
                <div className="sm:w-[40vw] w-full mx-auto z-50 top-0 bg-base-100 p-4 relative h-fit">
                <div className="btn mt-2 btn-primary btn-outline font-3 font-bold text-lg" onClick={() => setCurrentTranscript(null)}>
                        Exit Transcript<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
</svg>

                    </div>
                <p className="font-3 font-semibold text-3xl mt-2"><span className="">{leads[currentTranscript].phoneNumber}</span></p>

                    <p className="font-3 font-semibold text-3xl">Full Transcript</p>
                                        <p className="font-bold font-1 text-xl">{leads[currentTranscript].area}</p>

                    <div className="mt-4 p-2 bg-base-300 flex flex-col gap-4 min-h-[60vh] max-h-[70vh] overflow-y-auto">
                        <p className="text-center mb-4 font-3 font-bold text-lg w-full p-2 bg-secondary">Beginning of Conversation</p>
                        {leads[currentTranscript].transcript?.map((message, i) => (
                            message.sender.indexOf("@sniphomes.com") === -1 ?
                                <div key={i} className="w-fit flex flex-row items-center gap-4 mr-auto">
                                <p className="font-1 font-semibold">Other:</p>
                                <div className="bg-base-100 w-fit p-4 rounded-r-lg font-1 font-bold" >
                                    {message.message}
                                </div>
                                
                                </div>
                                

                                : 
                                <div key={i} className="w-fit flex flex-row items-center gap-4 ml-auto">
                                
                                <div className="bg-primary w-fit p-4 rounded-l-lg font-1 text-base-100 font-bold" >
                                    {message.message}
                                </div>
                                
                                
                                </div>

                            
                            
                        ))}
                        <p className="text-center mb-4 font-3 font-bold text-lg w-full p-2 bg-secondary">End of Conversation</p>
     

      


                    </div>

                    
                </div>


                
           

            </div>
            </>
            
        )}





        <div className="bg-base-100 p-4">
            <div className="bg-base-300 sm:p-10 p-4">
                <p className="font-3 font-semibold text-3xl">New Leads</p>


                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4 p-2 min-h-[50vh] items-center">

                {(!loading) ? 




(leads.length > 0) ? 
    leads.map((lead,i) => (
        (lead.new) && (
            <div key={i} className="w-full h-full bg-base-100 p-4">
            <p className="font-1">{lead.date}</p>
            <p className="font-3 font-bold text-2xl">{lead.area}</p>
            <p className="font-3 font-bold text-lg">Willing to {lead.action.substring(0,1).toUpperCase() + lead.action.substring(1)}</p>
            <p className="font-1 font-semibold mt-2">Phone Number: <span className=" border-2 border-neutral p-2 font-bold">{lead.phoneNumber}</span></p>
            <div className="p-3 bg-base-300 mt-4">
                <p className="font-1"><span className="font-bold">Conversation Summary:</span> {lead.leadDetails}</p>

            </div>
            <p className="text-sm font-3 text-error">Note: Conversation Summary may have inaccuracies.</p>
            <div className="flex flex-row mt-2 gap-4">
            <button className="btn btn-primary text-lg font-3 font-bold" onClick={() => setCurrentTranscript(i)}>
                <p>Open Transcript</p>
            </button>
            <button className="btn !btn-circle !btn-outline btn-error font-3" onClick={() => changeLeadStatus(i, lead)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
<path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
</svg>

            </button>

            </div>
            
        </div>
        )
        
    ))
    : 
    <div className="col-span-3 text-center bg-base-100 rounded-lg h-full flex flex-row items-center justify-center">
        <div className="font-3 ">
        <p className="font-bold text-4xl">No New Leads</p>
        <p>Check back soon :)</p>

        </div>
        
    </div>



                :
                
                <div className="w-full h-full flex flex-col bg-base-100 p-3">
                    <div className="flex flex-row justify-around items-center gap-4">
                        <div className="w-24 h-24 shrink-0 rounded-full skeleton flex ">
                        </div>

                        <div className="flex flex-col w-full gap-3">
                            {new Array(2).fill("null").map((_,i) => (
                                <div key={i} className="w-full h-6 rounded-full skeleton bg-base-300">

                                </div>



                            ))}
                            
                           


                        </div>

                        
                        
                    </div>
                    <div className="w-full h-full skeleton mt-4">
                        </div>
                    


                </div>
                
                
                }
                
                   
                    
                    
                </div>

               

            </div>

            <div className="bg-base-300 w-full sm:p-10 p-4 mt-4">
                <p className="font-1 font-semibold font-3 text-2xl">Dismissed Leads</p>



                <div className="grid grid-cols-1  sm:grid-cols-3  gap-4 mt-4 p-2 min-h-[50vh] items-center">

                {(!loading) ? 
                
                oldLeads.length > 0 ? 
                    oldLeads.map((lead,i) => (
               
                            <div key={i} className="w-full h-full bg-base-100 p-4">
                            <p className="font-1">{lead.date}</p>
                            <p className="font-3 font-bold text-2xl">{lead.area}</p>
                            <p className="font-3 font-bold text-lg">Willing to {lead.action.substring(0,1).toUpperCase() + lead.action.substring(1)}</p>
                            <p className="font-1 font-semibold mt-2">Phone Number: <span className=" border-2 border-neutral p-2 font-bold">{lead.phoneNumber}</span></p>
                            <div className="p-3 bg-base-300 mt-4">
                                <p className="font-1"><span className="font-bold">Conversation Summary: </span>{lead.leadDetails}</p>

                            </div>
                            <p className="text-sm font-3 text-error">Note: Conversation Summary may have inaccuracies.</p>
                            <div className="flex flex-row mt-2 gap-4">
                           
                            {/* <button className="btn !btn-circle !btn-outline btn-error font-3" onClick={() => setLeads((prevLeads) => {
                            let newLeads = prevLeads
                            newLeads[i].new = false
                            
                            return prevLeads
                            })}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
</svg>

                            </button> */}

                            </div>
                            
                        </div>
                        
                        
                    ))
                    : 
                    <div className="col-span-3 text-center bg-base-100 rounded-lg h-full flex flex-row items-center justify-center">
                        <div className="font-3 ">
                        <p className="font-bold text-4xl">No Dismissed Leads</p>

                        </div>
                        
                    </div>
                
                
                :
                <div className="w-full h-full flex flex-col bg-base-100 p-3">
                    <div className="flex flex-row justify-around items-center gap-4">
                        <div className="w-24 h-24 shrink-0 rounded-full skeleton flex ">
                        </div>

                        <div className="flex flex-col w-full gap-3">
                            {new Array(2).fill("null").map((_,i) => (
                                <div key={i} className="w-full h-6 rounded-full skeleton bg-base-300">

                                </div>



                            ))}
                            
                           


                        </div>

                        
                        
                    </div>
                    <div className="w-full h-full skeleton mt-4">
                        </div>
                    


                </div>
                }
                
                   
                    
                    
                </div>

            </div>

            
            




        </div>






            
        </>
    )
}